<script setup lang="tsx">
import { UiButton } from "#components"
import { VariantProps } from "class-variance-authority"

import { buttonVariants } from "@finq/ui/components/ui/Button.vue"

type AuthButtonProps = {
  authType: "login" | "signup"
  variant?: VariantProps<typeof buttonVariants>["variant"]
  isListItem?: boolean
}

const { t } = useI18n()
const login = useLogin()
const props = defineProps<AuthButtonProps>()

const route = useRoute()
const isHomePage = computed(() => isRouteName("index", route.name))
const emit = defineEmits(["closeMenu"])

const loginOpen = (isSignup: boolean) => {
  emit("closeMenu")

  login.open({}, isSignup ? "signup" : "login")
}
const authProps = computed(() => ({
  login: isHomePage.value ? { to: "/login" } : { onClick: () => loginOpen(false) },
  signup: isHomePage.value ? { to: "/signup" } : { onClick: () => loginOpen(true) },
}))

const RenderButton = computed(() => {
  switch (props.authType) {
    case "login":
      return (
        <UiButton
          {...authProps.value.login}
          data-action="signup"
          variant={props.variant}
          id={genClickId("menu_link_login")}
          key={isHomePage.value + "nav_login"}
          onClick={() => emit("closeMenu")}
        >
          {t("login")}
        </UiButton>
      )
    case "signup":
      return (
        <UiButton
          {...authProps.value.signup}
          data-action="signup"
          variant={props.variant}
          id={genClickId("menu_link_signup")}
          key={isHomePage.value + "nav_signup"}
          onClick={() => emit("closeMenu")}
        >
          {t("signup")}
        </UiButton>
      )
    default:
      return null
  }
})
</script>

<template>
  <RenderButton />
</template>

<i18n lang="json">
{
  "en": {
    "login": "Log in",
    "signup": "Sign up"
  },
  "he": {
    "login": "כניסה",
    "signup": "הרשמה"
  }
}
</i18n>
