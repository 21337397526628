<template>
  <div class="h-fit overflow-y-auto py-0.5">
    <ul
      class="md:py-s md:gap-xxs md:px-xxs flex flex-col md:border-t md:first:border-t-0"
      v-for="(key, index) in menuLinkKeys"
      :key="key"
    >
      <!-- Links -->
      <LayoutNavbarProductNavigation v-if="bp.isMobile.value && index === 0" @menu:close="closeMenu" />
      <li v-for="(action, index) in links[key]" :class="userMenuActiveClasses" :key="`${index}_${key}`">
        <UiButton
          :class="
            cn(
              'text-primary h-fit whitespace-pre rounded-none border-none px-5 py-2 text-sm leading-6 hover:bg-neutral-50 hover:font-normal md:px-4 md:text-base'
            )
          "
          :id="genClickId('menu_link', index)"
          variant="list-item"
          :to="action?.to"
          :disabled="Boolean(action.disabled)"
          @click="closeMenu"
        >
          {{ action.label }}
          <span v-if="action.disabled"> [{{ $t("common.coming_soon") }}] </span>
        </UiButton>
      </li>
    </ul>
    <!-- Actions -->
    <ul
      v-if="isAuthenticated"
      :class="cn('md:px-xxs md:border-t-border gap-xxs md:py-s flex flex-col md:border-t')"
    >
      <li :class="userMenuActiveClasses">
        <UiButton
          class="text-primary h-fit rounded-none border-none px-5 py-2 text-sm font-normal leading-6 hover:bg-neutral-50 hover:font-normal md:px-4 md:text-base"
          variant="list-item"
          @click="onLogout"
          :id="genClickId('menu_link_logout')"
        >
          {{ $t("authentication.buttons.logout") }}
        </UiButton>
      </li>
    </ul>

    <ul v-else :class="cn('gap-xxs px-xxs py-xxs md:border-t-border md:py-s flex flex-col md:border-t')">
      <li :class="userMenuActiveClasses">
        <LayoutUserMenuUserAuthButtons
          class="px-xs text-primary py-0 leading-none"
          auth-type="signup"
          variant="list-item"
          @close-menu="closeMenu"
        />
      </li>
      <li :class="userMenuActiveClasses">
        <LayoutUserMenuUserAuthButtons
          class="px-xs text-primary py-0 leading-none"
          auth-type="login"
          variant="list-item"
          @close-menu="closeMenu"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
export const userMenuActiveClasses = cn(
  "[&>button]:zag-focus:font-semibold zag-focus:font-semibold zag-focus:bg-accent zag-focus:text-accent-foreground transition-colors duration-100"
)
</script>

<script setup lang="ts">
import { type HeaderMenuLinks } from "../Navbar.vue"

const { isAuthenticated } = useUser()
const router = useRouterUtils()
const bp = useDisplay()

const emit = defineEmits(["update:modelValue"])

const props = defineProps<{ links: HeaderMenuLinks; modelValue: boolean }>()

const isUserMenuOpenModel = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
})

const closeMenu = () => (isUserMenuOpenModel.value = false)

const menuLinkKeys = computed(() => Object.keys(props.links) as (keyof HeaderMenuLinks)[])

const onLogout = () => {
  UserService.logout()
  closeMenu()
}

const isTabActive = (routeName: RoutesNamesList) => {
  try {
    const resolvedRoute = routerResolve({ name: routeName })
    return router.route.value.path.includes(resolvedRoute.path)
  } catch (e) {
    console.error("[UserMenuListItems]: ", e)
    return false
  }
}
</script>
